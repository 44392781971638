import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import { PersonnelApi, EntityApi, useAuth } from "@unity/components";
import config from "../config";

export default function UserAutocomplete({
  multiple,
  value = [],
  setValue,
  includeGroups,
  ...props
}) {
  const { auth } = useAuth();

  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let apiPromises = [PersonnelApi.getPersonnel()];
      if (includeGroups) {
        apiPromises.push(EntityApi.getEntityByType(config.groupEntityTypeId));
      }
      Promise.all(apiPromises).then((res) => {
        const localData = res.map((el) =>
          el.success ? (Array.isArray(el.data) ? el.data : el.data.data) : []
        );
        setData(localData.flat());
      });
    };
    getData();
  }, [includeGroups]);

  return (
    <Autocomplete
      multiple={multiple}
      options={data}
      value={value}
      onChange={(e, value) => setValue(value)}
      isOptionEqualToValue={(option, value) => {
        return option.uuid === value.uuid;
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={includeGroups ? "Search Users or Groups..." : "Search Users"}
        />
      )}
      getOptionDisabled={(option) => option?.uuid === auth.id}
      fullWidth
      {...props}
    />
  );
}
