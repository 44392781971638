import React, { forwardRef, useEffect, useState } from "react";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  DragAndDrop,
} from "@syncfusion/ej2-react-schedule";

import { CalendarApi, useAuth } from "@unity/components";
import "../syncfusionCalendar.css";
import Loading from "../Loading";
import { useWindowSize } from "../utils/useWindowSize";
import { getEventColor } from "../utils/getEventColor";

const Calendar = forwardRef(function Calendar(props, ref) {
  const { type, setCurrentEvent, update, onEventClick, onEventClickRead } =
    props;
  const { auth, checkPermission } = useAuth();
  const windowSize = useWindowSize();
  const readCalender = checkPermission("read_calendar_user");
  const updateCalendar = checkPermission("update_calendar_user");

  const [data, setData] = useState(false);
  const [itemHeight, setItemHeight] = useState(300);

  useEffect(() => {
    const getEventsData = async () => {
      if (type) {
        let apiPromises = [];
        let dataArr = [];
        type.forEach((val, key) => {
          if (val) {
            const obj = {
              application_uuid: auth.app_uuid,
              type: key,
              contact_id: auth.id,
            };
            apiPromises.push(CalendarApi.getEventsByFilter(obj));
          }
        });
        await Promise.all(apiPromises).then((res) => {
          res.forEach((el) => {
            if (el && el.success) {
              dataArr.push(el.data);
            }
          });
        });
        const localData = dataArr.flat();

        setData(localData);
      } else {
        setData([]);
      }
    };
    getEventsData();
  }, [update, type]);

  useEffect(() => {
    if (windowSize?.height) setItemHeight(windowSize.height - 110);
  }, [windowSize?.height]);

  const applyCategoryColor = (args) => {
    if (!args.element) return;
    args.element.style.backgroundColor = args.data.category_color
      ? args.data.category_color
      : args.data.type
      ? getEventColor(args.data.type)
      : "#757575";
  };

  const handlePopupOpen = (args) => {
    if (args.type === "Editor") {
      args.cancel = true;
      if (updateCalendar) {
        onEventClick();
      } else if (readCalender) {
        onEventClickRead();
      }
      const {
        type,
        subject,
        location,
        description,
        start,
        end,
        category_color,
        instance_uuid,
      } = args.data;
      const event = args.data.subject
        ? {
            type,
            subject,
            location,
            description,
            start,
            end,
            category_color,
            instance_uuid,
          }
        : {
            start,
            end,
            type,
          };
      setCurrentEvent(event);
    }
  };

  if (data) {
    return (
      <div
        className="tour-calendar"
        style={{ width: "100%", height: itemHeight }}
      >
        <ScheduleComponent
          ref={ref}
          height="100%"
          selectedDate={new Date()}
          showQuickInfo={false}
          popupOpen={handlePopupOpen}
          enablePersistence={true}
          eventRendered={(args) => {
            applyCategoryColor(args);
          }}
          eventSettings={{
            dataSource: data,
            fields: {
              id: { name: "id" },
              subject: { name: "subject" },
              startTime: { name: "start" },
              endTime: { name: "end" },
            },
          }}
        >
          <Inject
            services={[Day, Week, WorkWeek, Month, Agenda, DragAndDrop]}
          />
        </ScheduleComponent>
      </div>
    );
  } else {
    return <Loading />;
  }
});

export default Calendar;
