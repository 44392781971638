import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Toolbar as MuiToolbar,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Add from "@mui/icons-material/Add";

import { useAuth } from "@unity/components";
import { getEventColor } from "../utils/getEventColor";

export default function Toolbar({ eventTypes, type, setType, onAddEvent }) {
  const { checkPermission } = useAuth();
  const canCreate = checkPermission("create_calendar_user");

  const [all, setAll] = useState(true);
  const [tourOpen, setTourOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("tour", function (e) {
      if (e.detail?.open) {
        setTourOpen(e.detail.open);
      } else {
        setTourOpen((prev) => !prev);
      }
    });
  }, []);

  useEffect(() => {
    const temp = [];
    type.forEach((val) => temp.push(val));
    setAll(temp.every((el) => el));
  }, [type]);

  const handleChange = (e) => {
    console.log(e.target.name, e.target.checked);
    const localType = new Map(type);
    if (e.target.name === "all") {
      eventTypes.forEach((el) => {
        localType.set(el, e.target.checked);
      });
    } else {
      localType.set(e.target.name, e.target.checked);
    }
    setType(localType);
  };

  const addEventClick = () => {
    if (tourOpen) {
      onAddEvent();
      window.dispatchEvent(
        new CustomEvent("tourStepIndex", { detail: { stepIndex: 1 } })
      );
    } else {
      onAddEvent();
    }
  };

  return (
    <MuiToolbar variant="dense" className="tour-toolbar">
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Grid item>
          <Button
            onClick={addEventClick}
            endIcon={<Add />}
            variant="contained"
            disabled={!canCreate}
            size="small"
            className="tour-createEvent"
          >
            Add Event
          </Button>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" flexItem sx={{ height: 30 }} />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={all}
                onChange={handleChange}
                name="all"
              />
            }
            label="All"
          />
        </Grid>
        {eventTypes.map((el) => (
          <Grid item key={el}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={type.get(el)}
                  onChange={handleChange}
                  name={el}
                  sx={{
                    color: getEventColor(el),
                    "&.Mui-checked": {
                      color: getEventColor(el),
                    },
                  }}
                />
              }
              label={el}
            />
          </Grid>
        ))}
      </Grid>
    </MuiToolbar>
  );
}
