import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  Input,
} from "@mui/material";

import {
  EntityApi,
  CalendarApi,
  PersonnelApi,
  DateTimePickerComponent,
  DeleteButtonComponent,
  useAuth,
} from "@unity/components";
import UserAutocomplete from "./userAutocomplete";
import config from "../config";
import { checkArray } from "../utils/checkArray";
import { sendEmail } from "../utils/sendEmail";
import { getEventColor } from "../utils/getEventColor";
import { useTour } from "../services/TourContext";

export default function EventForm({
  eventTypes,
  open,
  setOpen,
  currentEvent,
  setCurrentEvent,
  setUpdate,
}) {
  const { auth, checkPermission } = useAuth();
  const tourOpen = useTour();
  const allowDelete = checkPermission("delete_calendar_manager");

  const [form, setForm] = useState(null);
  const [attendees, setAttendees] = useState([]);
  const [existingAttendees, setExistingAttendees] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [groups, setGroups] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [defaultColor, setDefaultColor] = useState(
    getEventColor(currentEvent?.type)
  );

  const newForm = currentEvent == null;

  useEffect(() => {
    const getGroups = async () => {
      const res = await EntityApi.getEntityByType(config.groupEntityTypeId);
      if (res && res.success) {
        setGroups(res.data.data);
      }
    };
    getGroups();
  }, []);

  useEffect(() => {
    if (!form?.category_color) setDefaultColor(getEventColor(form?.type));
  }, [form?.type, form?.category_color]);

  useEffect(() => {
    if (currentEvent) {
      setForm(JSON.parse(JSON.stringify(currentEvent)));
      const getAttendees = async () => {
        const res = await CalendarApi.getEventsByFilter({
          instance_uuid: currentEvent.instance_uuid,
        });
        let apiPromises = checkArray(res.data)
          ? res.data
              .filter((el) => el.contact_id !== auth.id)
              .map((el) => PersonnelApi.getPersonnelSingle(el.contact_id))
          : [];
        let localAttendees = [];
        Promise.all(apiPromises).then((res) => {
          if (checkArray(res)) {
            res.forEach((el) => {
              if (el && el.data) localAttendees.push(el.data);
            });
            setAttendees(localAttendees);
            setExistingAttendees(localAttendees);
          }
        });
      };
      if (currentEvent?.instance_uuid) getAttendees();
    } else {
      setForm({ type: "group", start: new Date(), end: new Date() });
      setAttendees([]);
      setExistingAttendees([]);
    }
  }, [currentEvent]);

  const getMembers = async (groupId) => {
    try {
      const res = await EntityApi.getEntityByType(
        config.groupMemberEntityTypeId,
        groupId
      );
      if (res && res.success) {
        if (checkArray(res.data.data)) {
          let localAttendees = JSON.parse(JSON.stringify(attendees));
          res.data.data.forEach((member) => {
            if (!localAttendees.find((el) => el.uuid === member.userId)) {
              localAttendees.push({ uuid: member.userId, name: member.name });
            }
          });
          setAttendees(localAttendees);
        }
      }
      setAnchorEl(null);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setForm({ type: "group", start: new Date(), end: new Date() });
    setCurrentEvent(null);
    setOpen(false);
    setDeleteOpen(false);
    if (tourOpen) {
      window.dispatchEvent(
        new CustomEvent("tourStepIndex", { detail: { stepIndex: 1 } })
      );
    }
  };

  const handleRes = (res) => {
    setUpdate((prev) => !prev);
    handleClose();
  };

  const createAttendeesEvents = async (attendees, res) => {
    if (checkArray(attendees)) {
      let apiPromises = [];
      attendees.forEach((el) => {
        let obj = {
          ...form,
          type_ref: res.data.typeRef,
          user_ref: auth.id,
          contact_id: el.uuid,
          instance_uuid: res.data.instance_uuid,
        };
        apiPromises.push(CalendarApi.storeEventV2(obj));
      });
      Promise.all(apiPromises).then((res) => {
        if (checkArray(res)) {
          res.forEach((el) => {
            if (el && el.success) {
              const member = attendees.find(
                (user) => user.uuid === el.data.contact_id
              );
              handleSendEmail(member?.email, "created", el.data);
            }
          });
        }
      });
    }
  };

  const checkAttendees = async (data) => {
    if (checkArray(attendees)) {
      //look for any newly added attendees and create
      const toCreate = attendees.filter((member) => {
        return (
          existingAttendees.find((el) => el.uuid === member.uuid) === undefined
        );
      });
      let createPromises = checkArray(toCreate)
        ? toCreate.map((el) => {
            const obj = {
              ...form,
              user_ref: auth.id,
              type_ref: form.type,
              contact_id: el.uuid,
            };
            if (data?.category_color) obj.category_color = form.category_color;
            return CalendarApi.storeEventV2(obj);
          })
        : [];
      Promise.all(createPromises).then((res) => {
        if (checkArray(res)) {
          res.forEach((el) => {
            if (el && el.success) {
              const member = toCreate.find(
                (user) => user.uuid === el.data.contact_id
              );
              handleSendEmail(member?.email, "created", el.data);
            }
          });
        }
      });
      //look for any removed attendees and delete
      const toDelete = existingAttendees.filter((member) => {
        return attendees.find((el) => el.uuid === member.uuid) === undefined;
      });
      console.log("people to delete: ", toDelete);
      const deletePromises = checkArray(toDelete)
        ? toDelete.map((el) => {
            return CalendarApi.getEventsByFilter({
              instance_uuid: currentEvent.instance_uuid,
              contact_id: el.uuid,
            });
          })
        : [];
      Promise.all(deletePromises).then((events) => {
        if (checkArray(events)) {
          events.forEach(async (el) => {
            if (el && el.success) {
              const res = await CalendarApi.deleteEventV2(el.data[0].id);
              if (res && res.success) {
                const member = toDelete.find(
                  (user) => user.uuid === el.data[0].contact_id
                );
                if (member?.email)
                  handleSendEmail(member.email, "deleted", el.data[0]);
              }
            }
          });
        }
      });
    }
  };

  const saveEvent = async () => {
    try {
      const obj = {
        ...form,
        user_ref: auth.id,
        contact_id: auth.id,
        type_ref: form.type,
      };
      if (form?.category_color) obj.category_color = form.category_color;
      if (form?.instance_uuid) {
        //check if attendees has changes
        const res = await CalendarApi.updateEventByType(
          form.instance_uuid,
          obj
        );
        if (res && res.success) {
          if (checkArray(attendees)) {
            attendees
              .filter((el) =>
                existingAttendees.find((member) => member.uuid === el.uuid)
              )
              .forEach((el) => {
                handleSendEmail(el.email, "updated", form);
              });
          }
          checkAttendees();
        }
        handleRes(res);
      } else {
        const res = await CalendarApi.updateEventV2(form.Id, obj);
        if (res && res.success) {
          createAttendeesEvents(attendees, res);
        }
        handleRes(res);
      }
    } catch (err) {
      console.log(err);
      handleRes();
    }
  };

  const createEvent = async () => {
    try {
      const obj = {
        ...form,
        user_ref: auth.id,
        contact_id: auth.id,
        type_ref: form.type,
      };
      if (form?.category_color) obj.category_color = form.category_color;
      const res = await CalendarApi.storeEventV2(obj);
      if (res && res.success) {
        createAttendeesEvents(attendees, res);
      }
      handleRes(res);
    } catch (err) {
      console.log(err);
      handleRes();
    }
  };

  const handleDelete = async () => {
    //waiting for api req
    console.log("current event: ", currentEvent);
    if (currentEvent?.instance_uuid) {
      const res = await CalendarApi.deleteEventByTypeInstance(
        currentEvent.instance_uuid
      );
      handleRes(res);
    } else {
      const res = await CalendarApi.deleteEventV2(currentEvent?.id);
      handleRes(res);
    }
  };

  const handleSendEmail = async (to, crudType, event) => {
    const subject = `Event ${crudType} - ${event.subject}`;
    const body = `Location - ${event.location}<br/>
    Date - ${event.start} - ${event.end}<br/>
    Description = ${event.description}<br/>
    `;
    const sendMail = await sendEmail(auth.app_uuid, {
      subject,
      body,
      to,
    });
  };

  const handleUpdateDate = (name, value) => {
    //if end date before new start date, set end date to same as start date
    const localForm = JSON.parse(JSON.stringify(form));
    const start = new Date(form.start);
    const end = new Date(form.end);
    const localValue = new Date(value.$d);
    if (name === "end") {
      if (start.getTime() > localValue.getTime()) {
        localForm.start = localValue.toISOString();
      }
      localForm.end = localValue.toISOString();
    } else if (name === "start") {
      if (end.getTime() < localValue.getTime()) {
        localForm.end = localValue.toISOString();
      }
      localForm.start = localValue.toISOString();
    }
    setForm(localForm);
  };

  const resetColor = () => {
    setForm((prev) => ({
      ...prev,
      category_color: null,
    }));
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent className="tour-eventForm">
          <Stack spacing={2}>
            <Typography
              onClick={(e) => setAnchorEl(e.currentTarget)}
              color="primary"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                width: 100,
              }}
              className="tour-groupAdd"
            >
              Add group
            </Typography>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              MenuListProps={{
                style: {
                  maxHeight: 300,
                  width: 200,
                },
              }}
            >
              {checkArray(groups)
                ? groups.map((el) => (
                    <MenuItem key={el._id} onClick={() => getMembers(el._id)}>
                      {el.name}
                    </MenuItem>
                  ))
                : null}
            </Menu>
            <UserAutocomplete
              multiple
              value={attendees}
              setValue={setAttendees}
              className="tour-userSearch"
            />
            <Select
              value={form?.type || ""}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, type: e.target.value }))
              }
              label="Type"
              className="tour-typeAdd"
            >
              {eventTypes.map((el) => (
                <MenuItem value={el} key={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Title"
              value={form?.subject || ""}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, subject: e.target.value }))
              }
              className="tour-titleInput"
            />
            <TextField
              label="Location"
              value={form?.location || ""}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, location: e.target.value }))
              }
            />
            <TextField
              multiline
              label="description"
              value={form?.description || ""}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, description: e.target.value }))
              }
            />
            <DateTimePickerComponent
              label="Start time"
              name="start"
              value={form?.start || new Date()}
              onChange={handleUpdateDate}
            />
            <DateTimePickerComponent
              label="End time"
              name="end"
              value={form?.end || new Date()}
              onChange={handleUpdateDate}
            />
            <FormControl
              size="small"
              fullWidth={true}
              margin="normal"
              component="fieldset"
              className="tour-colorAdd"
            >
              <FormLabel component="legend">color</FormLabel>
              <Typography
                onClick={resetColor}
                color="primary"
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  width: 100,
                }}
              >
                reset
              </Typography>
              <Input
                type="color"
                size="small"
                disableUnderline
                value={form?.category_color || defaultColor}
                onChange={(e) => {
                  setForm((prev) => ({
                    ...prev,
                    category_color: e.target.value,
                  }));
                }}
                sx={{ ".MuiInput-input": { height: 30, width: 60 } }}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ ml: 2, mr: 2, justifyContent: "space-between" }}>
          {newForm ? (
            <div></div>
          ) : (
            <DeleteButtonComponent
              disabled={!allowDelete}
              onClick={() => setDeleteOpen(true)}
            >
              Delete
            </DeleteButtonComponent>
          )}
          <div style={{ alignItems: "center" }}>
            <Button onClick={handleClose} className="tour-cancelButton">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={newForm ? createEvent : saveEvent}
              sx={{ width: 150 }}
              className="tour-saveButton"
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)}>Cancel</Button>
          <DeleteButtonComponent onClick={handleDelete}>
            Delete
          </DeleteButtonComponent>
        </DialogActions>
      </Dialog>
    </>
  );
}
