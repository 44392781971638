import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

import { AuthProvider } from "@unity/components";
import Home from "./screens";
import { TourContext } from "./services/TourContext";
import config from "./config";

export default function Root(props) {
  const [tourOpen, setTourOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("tour", function (e) {
      if (e.detail?.open) {
        setTourOpen(e.detail.open);
      } else {
        setTourOpen((prev) => !prev);
      }
    });
  }, []);

  return (
    <AuthProvider module_alias={config.resourceName} {...props}>
      <TourContext.Provider value={tourOpen}>
        {props.auth.access.calendar["A"] ? (
          <Home />
        ) : (
          <Typography>
            You do not have the permission to view this page
          </Typography>
        )}
      </TourContext.Provider>
    </AuthProvider>
  );
}
